import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivate } from '@angular/router';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGaurdService implements CanActivate {
  constructor(private router: Router, private commonService: CommonService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const token = sessionStorage.getItem('AccessToken');
    if (token !== null && token !== 'null') {
      return true;
    } else {
     // this.commonService.forceLogout();
     sessionStorage.clear();
      this.router.navigate(['/account/login']);

      return false;
    }
  }
}
