import { ConsumerLoginHistory } from "app/_shared/model/consumer-login-history";
import { ConsumerDetails } from "app/_shared/model/consumer-details";
import { User } from "./user.model";
export class LoginVM {
  email: string;
  password: string;
  isActive: boolean;
  consumerId: string;
  sessionId: string;
  operatingSystem: string;
  browserName: string;
  device: string;
  osVersion: string;
  browserVersion: string;
  latitude: string;
  longitude: string;
  ipAddress: string;
  token: string;
  loginTime: Date;
  logoutTime: Date;
  constructor() {
    this.email = null;
    this.password = null;
    this.isActive = false;
    this.consumerId = null;
    this.sessionId = null;
    this.operatingSystem = null;
    this.browserName = null;
    this.device = null;
    this.osVersion = null;
    this.browserVersion = null;
    this.latitude = null;
    this.longitude = null;
    this.ipAddress = null;
    this.token = null;
    this.loginTime = null;
    this.logoutTime = null;
  }
}
